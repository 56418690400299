/* 
This component is a nearly true copy of Datatable
Only thing changed is from line 286 to 306.
Adding a stonly tag to the first element of rows
PR: #1353

Expect this component to be removed after the initial month of trends v2
*/
import {
  Button,
  List,
  ListItem,
  PlaySnippetsButton,
  SortDirectionArrow,
} from '@capturi/ui-components'
import { Box, BoxProps, Flex, HStack, Text, Tooltip } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { Topic, TopicPhrase } from 'features/aiTopics'
import qs from 'query-string'
import React from 'react'
import { useSearchParams } from 'react-router'
import {
  CellProps,
  Column,
  Row,
  useFlexLayout,
  useSortBy,
  useTable,
} from 'react-table'

import { pctFormat } from '../../analytics/shared/utils'
import { usePeriodContext } from '../contexts/PeriodContext'
import {
  getHitratePercentageTooltip,
  getTrendPercentageDifferenceTooltip,
} from '../messages'
import TrendNumber from './TrendNumber'

type Props = {
  topics: Topic[]
  onSelectTopic?: (
    topic: Topic,
    phrase?: TopicPhrase,
    sortId?: string,
    sortDesc?: boolean,
  ) => void
  onPlaySnippets?: (topic: Topic) => void
}

const RightAlign = React.forwardRef<HTMLDivElement, BoxProps>(
  function RightAlign(props, ref) {
    return <Box ref={ref} textAlign="right" {...props} />
  },
)

const SORT_QUERY_KEY = 'sort'
const DEFAULT_SORT = {
  id: 'trend',
  desc: true,
}

const DataTable: React.FC<Props> = ({
  topics,
  onSelectTopic,
  onPlaySnippets,
}) => {
  const { period } = usePeriodContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const rawSortParam = searchParams.get(SORT_QUERY_KEY)
  const sortByConfig = React.useMemo(() => {
    if (rawSortParam == null) return [DEFAULT_SORT]
    try {
      return [JSON.parse(rawSortParam)]
    } catch {
      return [DEFAULT_SORT]
    }
  }, [rawSortParam])

  const columns = React.useMemo<Column<Topic>[]>(() => {
    const columns: Column<Topic>[] = [
      {
        Header: (): React.ReactElement => {
          return (
            <Tooltip
              label={t`The theme found across a series of conversations.`}
              aria-label={t`The theme found across a series of conversations.`}
              placement="top"
            >
              <Text>
                <Trans>Subject</Trans>
              </Text>
            </Tooltip>
          )
        },
        id: 'topic',
        accessor: 'name',
        width: 100,
      },
      {
        //Adoption feature to be deleted when feature is acceptally adopted
        Header: function ButtonHeader(): React.ReactElement {
          return (
            <HStack textAlign="left" width="100%">
              <Text>
                <Trans>Trends on trends</Trans>
              </Text>
            </HStack>
          )
        },
        id: 'trends-on-trends',
        width: 80,
        alignRight: true,
        accessor: (topic): Topic => topic,
        Cell: function TrendsOnTrendsCell() {
          return (
            <Tooltip
              label={t`Trends on trends`}
              aria-label={t`Trends on trends`}
              placement="bottom"
            >
              <Button variant="link" primary size="sm">
                <Trans>Show</Trans>
              </Button>
            </Tooltip>
          )
        },
        disableSortBy: true,
      },
      {
        Header: function HitsHeader(): React.ReactElement {
          return (
            <Tooltip
              label={t`The number of conversations where the topic was recognized.`}
              aria-label={t`The number of conversations where the topic was recognized.`}
              placement="top"
            >
              <RightAlign>
                <Trans>Conversations</Trans>
              </RightAlign>
            </Tooltip>
          )
        },
        id: 'hits',
        accessor: (topic): number => topic.hits,
        width: 90,
        sortDescFirst: true,
        Cell: function HitsCell({ value }: CellProps<Topic, number>) {
          return <RightAlign>{i18n.number(value)}</RightAlign>
        },
        alignRight: true,
        sortType: 'basic',
      },
      {
        Header: function HitRateHeader(): React.ReactElement {
          return (
            <Tooltip
              label={getHitratePercentageTooltip(period)}
              aria-label={getHitratePercentageTooltip(period)}
              placement="top"
            >
              <RightAlign>
                <Trans>% of all conversations</Trans>
              </RightAlign>
            </Tooltip>
          )
        },
        id: 'hitRate',
        accessor: (topic): number => topic.hitrate,
        width: 110,
        sortDescFirst: true,
        Cell: function HitRateCell({ value }: CellProps<Topic, number>) {
          return <RightAlign>{i18n.number(value, pctFormat)}</RightAlign>
        },
        alignRight: true,
        sortType: 'basic',
      },
      {
        Header: function TrendHeader(): React.ReactElement {
          return (
            <Tooltip
              label={getTrendPercentageDifferenceTooltip(period)}
              aria-label={getTrendPercentageDifferenceTooltip(period)}
              placement="top"
            >
              <RightAlign>
                <Trans>Trend</Trans>
              </RightAlign>
            </Tooltip>
          )
        },
        id: 'trend',
        accessor: 'trend',
        width: 70,
        sortDescFirst: true,
        Cell: function TrendCell({ value }: CellProps<Topic, number>) {
          return (
            <RightAlign>
              <TrendNumber value={value} />
            </RightAlign>
          )
        },
        alignRight: true,
        sortType: (rowA: Row<Topic>, rowB: Row<Topic>, columnId: string) => {
          const trendA = rowA.values[columnId]
          const trendB = rowB.values[columnId]
          return trendA - trendB
        },
      },
      {
        id: 'play',
        width: 30,
        accessor: (topic): Topic => topic,
        Cell: function PlayCell({ value }: CellProps<Topic, Topic>) {
          return (
            <RightAlign>
              <PlaySnippetsButton
                label={t`View conversations`}
                onClick={(e) => {
                  e.stopPropagation()
                  onPlaySnippets?.(value)
                }}
              />
            </RightAlign>
          )
        },
        disableSortBy: true,
      },
    ]

    return columns
  }, [period, onPlaySnippets])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: topics,
      autoResetSortBy: false,
      disableSortRemove: true,
      initialState: {
        sortBy: sortByConfig,
      },
    },
    useFlexLayout,
    useSortBy,
  )

  return (
    <Box {...getTableProps()}>
      <Box
        color="textMuted"
        fontWeight="medium"
        borderBottom="2px"
        borderBottomColor="gray.300"
      >
        {headerGroups.map((headerGroup) => {
          const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()
          return (
            <Box
              key={key}
              px={4}
              py={2}
              userSelect="none"
              {...headerGroupProps}
            >
              {headerGroup.headers.map((column) => {
                const { onClick, key, ...restProps } = column.getHeaderProps(
                  column.getSortByToggleProps({ title: undefined }),
                  // biome-ignore lint/suspicious/noExplicitAny: legacy
                ) as any

                return (
                  <Flex
                    key={key}
                    direction={column.alignRight ? 'row-reverse' : 'row'}
                    align="center"
                    title=""
                    {...restProps}
                    onClick={(e) => {
                      if (onClick === undefined) {
                        return
                      }
                      onClick(e)
                      const parsedQueryString = qs.parse(
                        searchParams.toString(),
                      )

                      const isSortedDesc =
                        column.isSortedDesc !== undefined
                          ? !column.isSortedDesc
                          : column.sortDescFirst

                      const newConfig = {
                        id: column.id,
                        desc: isSortedDesc,
                      }

                      const newParams = qs.stringify({
                        ...parsedQueryString,
                        [SORT_QUERY_KEY]: JSON.stringify(newConfig),
                      })

                      setSearchParams(newParams)
                    }}
                  >
                    {column.render('Header')}
                    {column.isSorted && (
                      <SortDirectionArrow
                        isSortedDesc={column.isSortedDesc}
                        mx={1}
                      />
                    )}
                  </Flex>
                )
              })}
            </Box>
          )
        })}
      </Box>
      <List disablePadding {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          // See explanation at the top
          const { key, ...rowProps } = row.getRowProps()
          return (
            <ListItem
              key={key}
              button
              onClick={() => {
                const { id, desc } = sortBy[0]
                onSelectTopic?.(row.original, undefined, id, desc)
              }}
              hasDivider
              alignItems="flex-start"
              {...rowProps}
            >
              {row.cells.map((cell) => {
                const { key, ...cellProps } = cell.getCellProps()
                return (
                  <Box key={key} {...cellProps}>
                    {cell.render('Cell')}
                  </Box>
                )
              })}
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}

export default DataTable
